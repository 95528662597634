// Speaker Theme
export default {
  breakpoints: ["48em", "64em", "75em"],
  space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256, 512],
  radii: {
    buttons: [0, ".25em", ".5em", "50%"]
  },
  sizes: {
    container: "1440px",
    testimonial: {
      container: "420px",
      avatar: "302px"
    },
    site: "1920px",
    paragraph: "592px"
  },
  fonts: {
    body: "DejaVu Sans, Roboto, sans-serif",
    heading: "Montserrat, sans-serif",
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 18, 20, 24, 36, 48, 60, 72, 96],
  fontWeights: {
    body: 400,
    bold: 700,
    extrabold: 800,
    heading: 900
  },
  lineHeights: {
    body: 1.8,
    heading: 1.125,
    hero: 1
  },
  letterSpacings: {
    body: "0.025em",
    heading: "0.5px",
    button: "0.05em"
  },
  colors: {
    black: "#424242",
    white: "#fff",
    body: "rgba(19, 19, 19, .87)",
    text: "#2D3550",
    background: "#fff",
    primary: "#FFD597",
    secondary: "#78E7CB",
    muted: "#f6f6f6",
    primaryGradient: "linear-gradient(90deg, #2D3550 3.41%, #506F94 100%)",
    gradient1: "linear-gradient(90deg, #4F00BC -1.01%, #0091EA 100%)",
    gradient2: "linear-gradient(93.94deg, #78E7CB 0.44%, #2CFAC7 101.35%)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      color: "black",
      lineHeight: "heading",
      fontWeight: "heading",
      letterSpacing: "heading",
      textTransform: "none",
      fontSize: [7, 7, 7, 8]
    },
    subheading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "extrabold",
      textTransform: "uppercase",
      letterSpacing: "heading",
      fontSize: [3, 4],
      color: "text"
    },
    title: {
      fontFamily: "heading",
      lineHeight: "body",
      fontWeight: "extrabold",
      fontSize: 0,
      textAlign: "center",
      letterSpacing: "heading"
    },
    testimonial: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: [1, 2],
      fontStyle: "italic",
      color: "text",
      textAlign: "center",
      letterSpacing: "body"
    },
    body: {
      color: "black",
      fontFamily: "body",
      fontSize: 2,
      lineHeight: "body",
      fontWeight: "body",
      letterSpacing: "body",
      margin: "12px 0"
    },
    hero: {
      fontFamily: "heading",
      lineHeight: "hero",
      fontWeight: "heading",
      fontSize: [7, 8],
      textTransform: "none"
      //      color: ["background", "black"],
    },
    heroSubheading: {
      fontWeight: "500",
      fontStyle: "italic",
      fontSize: ["16px", "18px"],
      lineHeight: "body",
      letterSpacing: "0",
      textTransform: "none",
      color: "primary",
      zIndex: 3
    }
  },
  dividers: {
    gradient: {
      background: theme => theme.colors.gradient1
    },
    muted: {
      background: "#eee"
    },
    white: {
      background: "#fff"
    },
    black: {
      background: theme => theme.colors.black
    }
  },
  sections: {
    gradient: {
      background: theme => theme.colors.gradient1
    },
    white: {
      background: "#fff"
    }
  },
  buttons: {
    root: {
      fontFamily: "heading",
      minWidth: "124px",
      textTransform: "uppercase",
      fontSize: "14px",
      fontWeight: "extrabold",
      letterSpacing: "button",
      color: "black",
      borderRadius: "0"
    },
    white: {
      color: "black",
      backgroundColor: "white"
    },
    primary: {
      color: "#fff",
      background: "linear-gradient(90deg, #4F00BC -1.01%, #0091EA 100%)",
      underline: "white"
    },
    borderTest: {
      gradient: "linear-gradient(101deg, #414249, #f6f6f6)",
      underline: "muted"
    },
    borderGradient: {
      underline: "primary"
    },
    border: {
      color: "#0091EA",
      fontFamily: "heading",
      border: "solid 3px transparent",
      backgroundImage:
        "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #4F00BC, #0091EA)",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box",
      boxShadow: "2px 1000px 1px #fff inset",
      borderRadius: "8px",
      padding: "12px 48px",
      textTransform: "uppercase",
      letterSpacing: "0.095em",
      fontSize: "12px",
      fontWeight: "800",
      minWidth: "224px",
      maxHeight: "53px",
      "&:hover": {
        opacity: 0.8,
        cursor: "pointer"
      }
    },

    whiteBorder: {
      color: "white",
      borderColor: "white",
      activeBgColor: "white",
      activeColor: "primary"
    },
    navlink: {
      backgroundColor: "transparent",
      color: "white",
      cursor: "pointer",
      fontFamily: "heading",
      fontWeight: "extrabold",
      fontSize: "14px",
      padding: "0px",
      textTransform: "uppercase",

      span: {
        "&:after": {
          borderColor: "primary"
        }
      }
    }
  },
  forms: {
    input: {
      borderColor: "black",
      color: "black",
      borderWidth: "2px",
      borderRadius: "0px",
      padding: "8px 24px 8px 24px",
      fontFamily: "body",
      fontSize: "14px",
      fontWeight: "600"
    },
    select: {
      borderColor: "black",
      color: "black",
      borderWidth: "2px",
      borderRadius: "0px",
      padding: "8px 24px 8px 24px",
      fontFamily: "body",
      fontSize: "14px",
      fontWeight: "600"
    },
    textarea: {
      borderColor: "black",
      color: "black",
      borderWidth: "2px",
      borderRadius: "0px",
      padding: "8px 24px 8px 24px",
      fontFamily: "body",
      fontSize: "14px",
      fontWeight: "600"
    }
  },
  styles: {
    hr: {
      marginTop: "18px",
      height: "3px",
      width: "224px",
      border: 0,
      color: "transparent",
      background: "linear-gradient(90deg, #4F00BC -1.01%, #0091EA 100%)"
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit"
      }
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit"
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid"
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid"
    },
    header: {
      height: "120px"
    },
    navlink: {
      fontFamily: "heading",
      textDecoration: "none",
      fontWeight: "800",
      fontSize: "14px",
      color: "text"
    }
  }
};
